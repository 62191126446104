.sale-list {
  height: 100%;
}
.list-item {
  height: 1.4rem;
  background: #fff;
  border-bottom: 1px solid #E1E1E1;
  padding: 0.3rem 0.24rem;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.list-item .item-title {
  font-size: 0.32rem;
  color: #333333;
  margin-bottom: 5px;
}
.list-item .item-content {
  font-size: 0.28rem;
  color: #999999;
}
.list-item .icon {
  height: 0.24rem;
  width: 0.24rem;
}
.list-item .icon img {
  height: 0.24rem;
  width: 0.24rem;
}
