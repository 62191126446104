
.sale-list{
  height:100%;
}
.list-item {
  height: 1.4rem;
  background: #fff;
  border-bottom: 1px solid #E1E1E1;
  padding: 0.3rem 0.24rem;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .item-title {
    font-size: 0.32rem;
    color: #333333;
    margin-bottom: 5px;
  }

  .item-content {
    font-size: 0.28rem;
    color: #999999;
  }

  .icon {
    height: 0.24rem;
    width: 0.24rem;

    img {
      height: 0.24rem;
      width: 0.24rem;
    }
  }
}
